import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

export default function trensferEabacusDialog({ closeDialog, data }) {
  return (
    <>
      <DialogContentText component="div" id="alert-dialog-slide-description">
        {data.selected.map((el) => (
          <div key={el}>{el}</div>
        ))}
      </DialogContentText>
      <DialogActions>
        <Button onClick={closeDialog}>{i18n.t('transfer.buttonClose')}</Button>
        <Button onClick={closeDialog}>{i18n.t('transfer.buttonApply')}</Button>
      </DialogActions>
    </>
  );
}
