import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import baselineTimer from '@iconify/icons-ic/baseline-timer';
import baselineTimerOff from '@iconify/icons-ic/baseline-timer-off';
import roundSystemUpdateAlt from '@iconify/icons-ic/round-system-update-alt';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { openDialog } from '../../../actions/dialog';

import store from '../../../store';

// ----------------------------------------------------------------------

export default function UserMoreMenu({ ...item }) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { info } = useSelector((state) => state.user);
  const roleId = info.role_id;
  const menuSchema = [
    {
      roleId: 1,
      onClick: () => {
        setIsOpen(false);
        openDialog({
          component: 'EditEabacuses',
          header: t('toolBar.edit'),
          data: { selected: [item.mac] }
        })(store.dispatch, store.getState);
      },
      icon: editFill,
      text: t('toolBar.edit')
    },
    {
      roleId: 1,
      onClick: () => {
        setIsOpen(false);
        openDialog({
          component: 'ManageMotoHours',
          header: t('toolBar.manageMotoHours'),
          data: { selected: [item.mac], state: true }
        })(store.dispatch, store.getState);
      },
      icon: baselineTimer,
      text: t('toolBar.manageMotoHours')
    },
    {
      roleId: 1,
      onClick: () => {
        setIsOpen(false);
        openDialog({
          component: 'ManageMotoHours',
          header: t('toolBar.switchOffMotoHours'),
          data: { selected: [item.mac], state: false }
        })(store.dispatch, store.getState);
      },
      icon: baselineTimerOff,
      text: t('toolBar.switchOffMotoHours')
    },
    {
      roleId: 3,
      onClick: () => {
        setIsOpen(false);
        openDialog({
          component: 'UpdateEsoroban',
          header: t('toolBar.updateAbacus'),
          data: { selected: [item.mac], state: false }
        })(store.dispatch, store.getState);
      },
      icon: roundSystemUpdateAlt,
      text: t('toolBar.updateAbacus')
    }
  ];
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 250, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {menuSchema
          .filter((el) => +el.roleId >= +roleId)
          .map((el, index) => (
            <MenuItem key={index} onClick={el.onClick} sx={{ color: 'text.secondary' }}>
              <ListItemIcon>
                <Icon icon={el.icon} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary={el.text} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
