import * as React from 'react';
import { closeDialog } from '../../../actions/dialog';
import store from '../../../store';

import FilterDialog from './filter';
import EditEabacuses from './edit';
import TransferEabacuses from './transfer';
import ManageMotoHours from './motoHours';
import AddEabacus from './add';
import UpdateEsoroban from './update';

export default function dialogComponents({ data }) {
  const handleClose = () => {
    closeDialog()(store.dispatch, store.getState);
  };
  const find = () => {
    switch (data.component) {
      case 'FilterDialog':
        return <FilterDialog data={data.data} closeDialog={handleClose} />;
      case 'EditEabacuses':
        return <EditEabacuses data={data.data} closeDialog={handleClose} />;
      case 'TransferEabacuses':
        return <TransferEabacuses data={data.data} closeDialog={handleClose} />;
      case 'ManageMotoHours':
        return <ManageMotoHours data={data.data} closeDialog={handleClose} />;
      case 'AddEabacus':
        return <AddEabacus data={data.data} closeDialog={handleClose} />;
      case 'UpdateEsoroban':
        return <UpdateEsoroban data={data.data} closeDialog={handleClose} />;
      default:
        return null;
    }
  };
  return find();
}
