/* eslint-disable */

import store from '../store';
import API_ROOT from '../constants/urlAPIEabacus';
import { OLD_API } from '../constants/oldApiUrl';
import Auth from './auth.service';
import { resume, logOut } from '../actions/user';
import { toastInfo, toastWarning } from '../actions/toast';
import history from '../history';
export const GET = (...args) => makeRequest('GET', ...args);
export const POST = (...args) => makeRequest('POST', ...args);
export const PUT = (...args) => makeRequest('PUT', ...args);
export const DELETE = (...args) => makeRequest('DELETE', ...args);
export const PATCH = (...args) => makeRequest('PATCH', ...args);

const formData = (object) =>
  Object.keys(object).reduce((form, key) => {
    form.append(key, object[key]);
    return form;
  }, new FormData());

const processErrors = async (json, method, endpoint, rawData, multipart) => {
  if (json.statusCode === 401) {
    if (json.error === 'Unauthorized' && json.message === 'invalid token') {
      toastInfo(`${json.error} => ${json.message}`);
      logOut()(store.dispatch, store.getState);
      return false;
    } else if (json.error === 'Unauthorized' && json.message === 'jwt expired') {
      const { refresh_token } = store.getState().user;
      return Auth.Resume({ refresh_token }).then(async (response) => {
        resume(response)(store.dispatch, store.getState);
        return await makeRequest(method, endpoint, rawData, multipart);
      });
    }
  }
  return json;
};

async function makeRequest(method, endpoint, rawData = false, multipart = false) {
  const accessToken = store.getState().user && store.getState().user.access_token;
  const headers = new Headers({
    'Content-Type': 'application/json'
  });
  if (accessToken) {
    headers.append('authorization', `Basic ${accessToken}`);
  }

  const options = {
    method,
    headers,
    mode: 'cors',
    cache: 'default'
  };
  const body = !!rawData && ((multipart && formData(rawData)) || JSON.stringify(rawData));

  if (body) {
    Object.assign(options, { body });
  }

  const request = new Request(API_ROOT + endpoint, options);

  try {
    const data = await fetch(request);
    const json = await data.json();
    if (json && json.error) return processErrors(json, method, endpoint, rawData, multipart);
    if (json) return json;
  } catch ({ message, name, number, url }) {
    if (number === 403) {
      if (message === 'Token has been changed') {
        localStorage.setItem('esorobanStore', JSON.stringify({}));
        document.location = `${document.location.origin}/login`;
      }
    } else if (number === 401) {
      if (message === 'Token recovery is not subject to') {
        localStorage.setItem('esorobanStore', JSON.stringify({}));
        document.location.reload(true);
      }
      // console.log("there is 401");
    } else {
      return Promise.reject(name === 'API' ? message : { message, name, number, url });
    }
  }
}

const makeToQuery = (query, noGet = false) => {
  if (!query) return '';
  const str = [];
  for (const p of Object.keys(query)) {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(query[p])}`);
  }

  if (noGet) return str.join('&');
  return `?${str.join('&')}`;
};

export const ApiConnector = (prefix = '') => {
  return (callback, endpoint, multipart = false, query = false) => ({
    async call(body = false, skipGlobalErrorStack = false) {
      try {
        return await callback(...[prefix + endpoint + makeToQuery(query), body, multipart]);
      } catch ({ message }) {
        if (message) {
          toastWarning(`${message}`);
        }
        // store.dispatch({
        //     type: Types.API_ERROR,
        //     payload: {
        //         id: uuid(),
        //         time: Date.now(),
        //         endpoint: prefix + endpoint,
        //         body,
        //         message
        //     }
        // });
        //
        // if (skipGlobalErrorStack) throw new Error(message);
        // else {
        //     enqueueSnackbar({
        //         message,
        //         options: {
        //             variant: "error"
        //         }
        //     })(store.dispatch);
        // }
      }
    }
  });
};
