import store from '../store';
import { setWSSEabacuses } from './eabacus';

export function connectWs(ws) {
  ws.onopen = () => {
    const sendData = {
      ea_cmd: 10000,
      psw: 'v',
      uname: 'v'
    };
    ws.send(JSON.stringify(sendData));
  };
  ws.onmessage = (event) => {
    const { data } = store.getState().esoroban;
    const json = JSON.parse(event.data);
    try {
      if (json && json.idx < 999998) {
        const jsonData = json.state.split('|');
        const toSave = {
          mac: jsonData[0],
          id: jsonData[1].replace(/\/\/.*|.*test.*/gi, '').trim(),
          tag: jsonData[2],
          con: jsonData[3],
          st: jsonData[4],
          digits: jsonData[5],
          rssi: jsonData[6],
          bat: jsonData[7],
          fw: jsonData[8],
          upd: jsonData[9],
          ch: jsonData[10],
          seen: jsonData[11],
          ip: jsonData[12],
          ssid: jsonData[13]
        };
        const inRedux = data.findIndex((el) => el.mac === toSave.mac);
        if (inRedux !== -1) setWSSEabacuses(toSave, inRedux)(store.dispatch, store.getState);
      }
    } catch (err) {
      console.log(err);
    }
  };
}
