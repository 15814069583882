import base64url from 'base64url';

import EabacusService from '../services/eabacus.service';
import Types from '../constants/esoroban';
import { setFirmwareVersions } from './usersList';

function encodeFilter(data) {
  data = data || [];
  const encodedFilter = data.reduce(
    (result, item) => (item.values && { ...result, [item.code]: item.values }) || result,
    {}
  );
  return base64url.encode(encodeURIComponent(JSON.stringify(encodedFilter)));
}

function changeFilterValues(filter) {
  return async (dispatch) => {
    dispatch({
      type: Types.DATA,
      payload: {
        filter
      }
    });
  };
}
function getEabacusFirmware() {
  return async (dispatch) => {
    const res = await EabacusService.GetFirmwareVersions();
    dispatch(setFirmwareVersions(res));
    return res;
  };
}

function disableMotoH(dataSend) {
  return async (dispatch) => {
    const res = await EabacusService.DisableMotoH(dataSend);
    return res;
  };
}

function enableMotoH(dataSend) {
  return async (dispatch) => {
    const res = await EabacusService.EnableMotoH(dataSend);
    return res;
  };
}

function updateEsoroban(dataSend) {
  return async (dispatch, getState) => {
    const res = await EabacusService.UpdateEsoroban(dataSend);
    const { data } = getState().esoroban;
    // eslint-disable-next-line
    for (const item of dataSend.macs) {
      const index = data.findIndex((el) => el.mac === item);
      if (index !== -1) data[index] = { ...data[index], upd: '1%' };
    }
    dispatch({
      type: Types.DATA,
      payload: {
        data
      }
    });
    return res;
  };
}

function processStTags(item) {
  const closedBdi = item.split('</bdi>');
  const result = [];
  if (closedBdi.length > 1) {
    // eslint-disable-next-line
    for (const item of closedBdi) {
      if (item) result.push(item.split('>')[1]);
    }
  }
  return result;
}

function setWSSEabacuses(item, index) {
  return (dispatch, getState) => {
    const { data } = getState().esoroban;
    const st = processStTags(item.st);
    if (data.length) {
      data[index] = {
        ...data[index],
        serialNumber: item.id,
        tag: item.tag,
        con: item.con,
        st: item.st,
        digits: item.digits,
        rssi: item.rssi,
        battery: item.bat,
        fw: item.fw ? Number(item.fw) : '',
        upd: (item.upd && item.upd.includes('>') && processStTags(item.upd))[0] || item.upd,
        ch: item.ch,
        seen: item.seen,
        ip: item.ip,
        ssid: item.ssid,
        wsUpdated: true,
        online: (st.includes('L') && 'online') || 'offline',
        motoHours: (st.includes('W') && 1) || 0
      };
      dispatch({
        type: Types.DATA,
        payload: {
          data
        }
      });
    }
  };
}

function setTableSettings(item) {
  return async (dispatch) => {
    dispatch({
      type: Types.DATA,
      payload: item
    });
  };
}

function getAllEabacuses(data, params = false) {
  return async (dispatch) => {
    const filters = (data.filter.length && { filters: encodeFilter(data.filter) }) || {};
    dispatch({
      type: Types.DATA,
      payload: {
        data: [],
        count: 0,
        noData: false
      }
    });
    if (params) dispatch(setTableSettings(params));
    const res = await EabacusService.GelAllEabacuses({
      order: 'ASC',
      page: (params && params.page) || data.page,
      take: (params && params.rowsPerPage) || data.rowsPerPage,
      ...filters
    });

    try {
      if (res) {
        if (res.data.length === 0) {
          dispatch({
            type: Types.DATA,
            payload: { noData: true }
          });
          return;
        }
        dispatch({
          type: Types.DATA,
          payload: {
            ...res,
            data: res.data.map((el) => ({
              ...data.data.find((item) => item.mac === el.mac),
              mac: el.mac,
              status: el.status,
              contract: el.contract,
              price: el.price,
              serialNumber: '',
              ownerId: el.owner_id,
              payPast: el.pay_past,
              payNext: el.pay_next,
              franchName: `${el.franch_first_name ? `${el.franch_first_name} ` : ''}${
                el.franch_last_name || ''
              }`,
              franchEmailLogin: el.franch_email_login,
              franchGeoId: el.franch_geo_id,
              franchCountry: `${
                (el.franch_country &&
                  el.franch_country.split('|||').length > 1 &&
                  el.franch_country.split('|||')[0]) ||
                ''
              }`,
              franchCity: `${
                (el.franch_city &&
                  el.franch_city.split('|||').length > 1 &&
                  el.franch_city.split('|||')[0]) ||
                ''
              }`,
              studentName: `${el.student_first_name ? `${el.student_first_name} ` : ''}${
                el.student_last_name || ''
              }`,
              studentEmailLogin: el.student_email_login,
              wsUpdated: false,
              online: 'pending',
              motoHours: -1,
              battery: -1,
              fw: -1,
              ip: -1,
              ssid: -1
            })),
            noData: false
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
    return res;
  };
}

function addNewAbacus(data) {
  return async (dispatch) => {
    const res = await EabacusService.AddNewAbacus(data);
    return res;
  };
}

function editEabacus(data) {
  return async (dispatch) => {
    const res = await EabacusService.EditEabacus(data);
    return res;
  };
}

export {
  editEabacus,
  addNewAbacus,
  getAllEabacuses,
  setWSSEabacuses,
  setTableSettings,
  updateEsoroban,
  enableMotoH,
  disableMotoH,
  changeFilterValues,
  getEabacusFirmware
};
