import Types from '../constants/usersList';

const defaultState = {
  franch: [],
  countries: [],
  macValues: [],
  firmware: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case Types.SET_USERS_LIST:
      localStorage.setItem(
        'esorobanStore',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('esorobanStore')),
          usersList: { ...state, ...action.payload }
        })
      );
      return { ...state, ...action.payload };
    case Types.CLEAR_USERS_LIST:
      return { ...state, ...defaultState };
    default:
      return state;
  }
};
