/* eslint-disable */

import { ApiConnector, GET, POST, PATCH } from './eabacusRequests.service';

export default (() => {
  const API = ApiConnector('/eabacus');
  return {
    EditEabacus: async (data) => {
      const res = await API(PATCH, '').call(data);
      return res;
    },
    GelAllEabacuses: async (query) => {
      const res = await API(GET, '', false, { ...query, t: +new Date() }).call();
      return res;
    },
    AddNewAbacus: async (data) => {
      const res = await API(POST, '').call(data);
      return res;
    },
    ValidateEabacusMac: async (mac) => {
      const res = await API(GET, `/validate/${mac}`, false, { t: +new Date() }).call();
      return res;
    },
    UpdateEsoroban: async (data) => {
      const res = await API(POST, `/update/firmWare`).call(data);
      return res;
    },
    DisableMotoH: async (data) => {
      const res = await API(POST, `/moto/disable`).call(data);
      return res;
    },
    EnableMotoH: async (data) => {
      const res = await API(POST, `/moto/enable`).call(data);
      return res;
    },
    GetFirmwareVersions: async () => {
      const res = await API(GET, `/firmware`).call();
      return res;
    }
  };
})();
