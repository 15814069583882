import Types from '../constants/usersList';
import AuthService from '../services/auth.service';
import CountriesService from '../services/countries.service';

import { getUsers } from './user';

export function getFranchList(id = false, refresh = false) {
  return async (dispatch) => {
    let emulationToken;
    if (refresh) {
      dispatch({
        type: Types.SET_USERS_LIST,
        payload: {
          franch: []
        }
      });
    }
    if (id) {
      emulationToken = await AuthService.Emulation({ id });
    }
    const res = await getUsers(
      false,
      { role_id: 3, size: 9999 },
      (emulationToken && emulationToken.token) || false
    );
    if (res && res.list) {
      dispatch({
        type: Types.SET_USERS_LIST,
        payload: {
          franch: res.list.filter((el) => el.city)
        }
      });
    }
  };
}

export function getCountriesList() {
  return async (dispatch) => {
    const res = await CountriesService.CountriesList(false, { size: 9999 });
    if (res && res.list) {
      dispatch({
        type: Types.SET_USERS_LIST,
        payload: {
          countries: res.list
        }
      });
    }
  };
}

export function setMacValues(macValues) {
  return (dispatch) => {
    dispatch({
      type: Types.SET_USERS_LIST,
      payload: {
        macValues
      }
    });
  };
}

export function setFirmwareVersions(firmware) {
  return (dispatch) => {
    dispatch({
      type: Types.SET_USERS_LIST,
      payload: {
        firmware
      }
    });
  };
}
