import Types from '../constants/esoroban';

const defaultState = {
  data: [],
  count: 0,
  totalPages: 0,
  page: 1,
  rowsPerPage: 5,
  filter: [],
  noData: false
};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.DATA:
      localStorage.setItem(
        'esorobanStore',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('esorobanStore')),
          esoroban: {
            data: [],
            filter: (action.payload && action.payload.filter) || state.filter,
            count: (action.payload && action.payload.count) || state.count,
            page: (action.payload && action.payload.page) || state.page,
            rowsPerPage: (action.payload && action.payload.rowsPerPage) || state.rowsPerPage
          }
        })
      );
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
