import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import arrowheadRight from '@iconify/icons-eva/arrowhead-right-outline';
import editFill from '@iconify/icons-eva/edit-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import add from '@iconify/icons-ic/add';
import baselineTimer from '@iconify/icons-ic/baseline-timer';
import baselineTimerOff from '@iconify/icons-ic/baseline-timer-off';
import roundSystemUpdateAlt from '@iconify/icons-ic/round-system-update-alt';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  LinearProgress,
  Badge,
  Chip
} from '@mui/material';
import { openDialog } from '../../../actions/dialog';
import store from '../../../store';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  fetch: PropTypes.array,
  selected: PropTypes.array
};

export default function UserListToolbar({
  numSelected,
  filterName,
  onFilterName,
  selected,
  fetch
}) {
  const { t } = useTranslation();
  const { filter } = useSelector((state) => state.esoroban);
  const { info } = useSelector((state) => state.user);
  const roleId = info.role_id;
  return (
    <>
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter'
          })
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} {t('toolBar.selected')}
          </Typography>
        ) : (
          <>
            <SearchStyle
              value={filterName}
              onChange={onFilterName}
              placeholder={t('placeholder.search_by_MAC')}
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />
            {fetch && fetch.includes(false) && false && <LinearProgress sx={{ width: '50%' }} />}
          </>
        )}

        {numSelected > 0 ? (
          <div>
            {roleId === 1 && (
              <>
                <Tooltip title={t('toolBar.edit')}>
                  <IconButton
                    onClick={() =>
                      openDialog({
                        component: 'EditEabacuses',
                        header: t('toolBar.edit'),
                        data: { selected }
                      })(store.dispatch, store.getState)
                    }
                  >
                    <Icon icon={editFill} />
                  </IconButton>
                </Tooltip>
                {false && (
                  <Tooltip title={t('toolBar.transferAbacus')}>
                    <IconButton
                      onClick={() =>
                        openDialog({
                          component: 'TransferEabacuses',
                          header: t('toolBar.transferAbacus'),
                          data: { selected }
                        })(store.dispatch, store.getState)
                      }
                    >
                      <Icon icon={arrowheadRight} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title={t('toolBar.manageMotoHours')}>
                  <IconButton
                    onClick={() =>
                      openDialog({
                        component: 'ManageMotoHours',
                        header: t('toolBar.manageMotoHours'),
                        data: { selected, state: true }
                      })(store.dispatch, store.getState)
                    }
                  >
                    <Icon icon={baselineTimer} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('toolBar.switchOffMotoHours')}>
                  <IconButton
                    onClick={() =>
                      openDialog({
                        component: 'ManageMotoHours',
                        header: t('toolBar.switchOffMotoHours'),
                        data: { selected, state: false }
                      })(store.dispatch, store.getState)
                    }
                  >
                    <Icon icon={baselineTimerOff} />
                  </IconButton>
                </Tooltip>
              </>
            )}

            <Tooltip title={t('toolBar.updateAbacus')}>
              <IconButton
                onClick={() =>
                  openDialog({
                    component: 'UpdateEsoroban',
                    header: t('toolBar.updateAbacus'),
                    data: { selected, state: false }
                  })(store.dispatch, store.getState)
                }
              >
                <Icon icon={roundSystemUpdateAlt} />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <div>
            <Tooltip title={t('filter.title')}>
              <Badge badgeContent={filter.length} color="primary">
                <IconButton
                  onClick={() =>
                    openDialog({ component: 'FilterDialog', header: t('filter.header') })(
                      store.dispatch,
                      store.getState
                    )
                  }
                >
                  <Icon icon={roundFilterList} />
                </IconButton>
              </Badge>
            </Tooltip>
          </div>
        )}
      </RootStyle>
    </>
  );
}
