import * as Yup from 'yup';
import { useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Collapse,
  Typography,
  Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { logIn, checkOldApi, setProfile } from '../../../actions/user';
import store from '../../../store';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('login.wrongFormat')).required(t('login.enterEmail')),
    password: Yup.string().required(t('login.enterPassword'))
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      setError('');
      const oldApiCheck = await checkOldApi({
        log: values.email,
        pwd: btoa(values.password.replace(/\s/g, ''))
      });
      if (oldApiCheck) {
        const res = await logIn({
          email: values.email,
          passwordHash: btoa(unescape(encodeURIComponent(values.password)))
        })(store.dispatch, store.getState);
        const profile =
          res &&
          res.multiprofile.filter((el) => el.role_id === 3 || el.role_id === 2 || el.role_id === 1);
        if (profile && !profile.length) {
          setError(t('error.userRole'));
          return;
        }

        await setProfile(profile[0])(store.dispatch, store.getState);
        navigate('/dashboard/esoroban', { replace: true });
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {false && (
            <>
              <FormControlLabel
                control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                label="Remember me"
              />

              <Link component={RouterLink} variant="subtitle2" to="#">
                {t('login.forgotPassword')}
              </Link>
            </>
          )}
        </Stack>
        <Collapse in={!!error}>
          <Box pt={2} pb={2}>
            <Typography variant="error" component="p">
              {error}
            </Typography>
          </Box>
        </Collapse>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {t('buttons.enter')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
