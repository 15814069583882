export default {
  LOGIN: '@__set_email_password_get_profile',
  SETPROFILE: '@__set_profile_from_login',
  LOGOUT: 'LOGOUT',
  RESETVIEW: '@change_reset_view',
  RESUME: '@_resume_user_token',
  EMULATION: '@__emulation_login_to_user',
  LANG: '@__switch_user_language',
  STATISTICS: '@get_statistics_student_data',
  NEW_HW_HEIGHT: '@set_user_new_hw_height'
};
