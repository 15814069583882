import esoroban from './esoroban';
import user from './user';
import dialog from './dialog';
import usersList from './usersList';

export default {
  esoroban,
  user,
  usersList,
  dialog
};
