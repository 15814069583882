import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//

import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
import { User } from './pages/User';
import NotFound from './pages/Page404';

import store from './store';

// ----------------------------------------------------------------------

export default function Router() {
  const { loggedin } = useSelector((state) => state.user);
  return useRoutes([
    {
      path: '/dashboard',
      element: loggedin ? <DashboardLayout /> : <Navigate to="/login" replace />,
      children: [
        {
          element: <Navigate to="/dashboard/esoroban" replace />
        },
        { path: 'esoroban', element: <User /> },
        { path: 'app', element: <DashboardApp /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
