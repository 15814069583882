import Types from '../constants/user';

const defaultState = {
  lang: null,
  info: null,
  loggedin: false,
  transactionKey: null,
  multiprofile: [],
  access_token: null,
  loginView: false,
  resetPass: false,
  emulation: [],
  permission: [],
  statistics: null,
  animation: 0,
  loader: false,
  newHwHeight: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case Types.LOGIN:
    case Types.LANG:
    case Types.SETPROFILE:
    case Types.RESETVIEW:
    case Types.EMULATION:
    case Types.STATISTICS:
    case Types.NEW_HW_HEIGHT:
    case Types.RESUME:
      return { ...state, ...action.user };
    case Types.LOGOUT:
      return { ...state, ...defaultState };
    default:
      return state;
  }
};
