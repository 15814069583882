import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import uaLocale from 'date-fns/locale/uk';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField, Box, DatePicker, Typography } from '@mui/material';
import { Field, reduxForm, Form, change, SubmissionError } from 'redux-form';
import PropTypes from 'prop-types';
import { enableMotoH, disableMotoH } from '../../../actions/eabacus';
import { closeDialog } from '../../../actions/dialog';
import { toastSuccess } from '../../../actions/toast';

import store from '../../../store';

const renderDatePicker = ({
  input: { onBlur, value, ...inputProps },
  meta: { touched, error },
  ...custom
}) => {
  const _val = new Date(value);
  if (value) {
    // eslint-disable-next-line
    let date = _val == 'Invalid Date' ? new Date(JSON.parse(value)) : _val;
    date = new Date(date);
    const _date = `${date.getFullYear()}-${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}T00:00:00`;
    value = _date;
  }
  const onChange = (dates) => {
    inputProps.onChange((Date.parse(dates) && dates.toISOString()) || null);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={uaLocale}>
      <MobileDatePicker
        {...inputProps}
        {...custom}
        autoOk
        allowKeyboardControl={false}
        error={touched && !!error}
        animateYearScrolling
        disablePast
        variant="inline"
        inputVariant="outlined"
        format="dd.MM.yyyy"
        value={value ? new Date(value) : null}
        onChange={onChange}
        renderInput={(params) => (
          <TextField {...custom} {...params} error={touched && !!error} required />
        )}
      />
    </LocalizationProvider>
  );
};

const handleSubmit = async (dataForm, dispatch) => {
  if (dataForm && !Object.keys(dataForm).includes('dateChange') && dataForm.state) {
    throw new SubmissionError({ dateChange: 'error' });
  }
  const toSend = {
    macs: dataForm.macs
  };
  let res;
  if (dataForm.state) {
    toSend.dateChange = dataForm.dateChange;
    res = await dispatch(enableMotoH(toSend));
  } else {
    res = await dispatch(disableMotoH(toSend));
  }
  if (res) {
    dispatch(closeDialog());
    toastSuccess(`${i18n.t(`manageMotoHours.toastSuccess`)}`);
  }
};

export function ManageMotoHoursDialog({ data, ...props }) {
  const { state } = data;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(change('MotoHours', 'macs', data.selected));
    dispatch(change('MotoHours', 'state', state));
  }, []);
  const margin = 2;
  return (
    <Form onSubmit={props.handleSubmit}>
      <DialogContentText component="div" sx={{ width: 300 }} id="alert-dialog-slide-description">
        {`${t('manageMotoHours.on')} ${
          (data.selected.length > 1 && `${data.selected.length} ${t('manageMotoHours.abacus')}`) ||
          `${t('manageMotoHours.abacus')} ${data.selected[0]}`
        }  ${t('manageMotoHours.will')} `}
        <span style={{ textDecoration: 'underline' }}>
          {`${(state && t('manageMotoHours.includ')) || t('manageMotoHours.off')}`}
        </span>
        {` ${t('manageMotoHours.motoHours')}`}
        {state && (
          <Box mb={margin} mt={margin}>
            <Field
              name="dateChange"
              sx={{ width: 300 }}
              component={renderDatePicker}
              required
              label={t('manageMotoHours.activationDate')}
              variant="outlined"
            />
          </Box>
        )}
      </DialogContentText>
      <DialogActions>
        <Button onClick={props.closeDialog}>{t('manageMotoHours.buttonClose')}</Button>
        <Button type="submit">{t('manageMotoHours.buttonApply')}</Button>
      </DialogActions>
    </Form>
  );
}

ManageMotoHoursDialog.propTypes = {
  closeDialog: PropTypes.func,
  data: PropTypes.object
};

export default reduxForm({
  onSubmit: handleSubmit,
  form: 'MotoHours',
  enableReinitialize: true
})(ManageMotoHoursDialog);
