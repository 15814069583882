import { useState, useEffect } from 'react';
import { Field, reduxForm, Form, reset, SubmissionError, untouch, change } from 'redux-form';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Box,
  DatePicker,
  Chip,
  Collapse,
  Typography
} from '@mui/material';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import uaLocale from 'date-fns/locale/uk';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LoadingButton } from '@mui/lab';

import InputMask from 'react-input-mask';
import store from '../../../store';

import { setMacValues } from '../../../actions/usersList';
import { addNewAbacus, getAllEabacuses } from '../../../actions/eabacus';
import { closeDialog } from '../../../actions/dialog';
import { toastSuccess } from '../../../actions/toast';

import EabacusService from '../../../services/eabacus.service';

const renderMacTextField = ({ input, meta: { touched, asyncValidating, error }, ...custom }) => (
  <InputMask mask="**-**-**-**-**-**" {...input} {...custom}>
    {(inputProps) => (
      <TextField
        {...custom}
        value={inputProps.value}
        onChange={inputProps.onChange}
        error={!!error}
        helperText={!!error && error}
        InputProps={{
          ...inputProps,
          endAdornment: (
            <div>
              {(asyncValidating && <CircularProgress color="inherit" size={20} />) || null}
              {inputProps.endAdornment}
            </div>
          )
        }}
      />
    )}
  </InputMask>
);

const renderSelect = ({ input, meta: { touched, error }, i18n, ...custom }) => (
  <Autocomplete
    options={custom.options || []}
    sx={{ width: '100%' }}
    getOptionLabel={(option) => {
      let optionText;
      if (input.name === 'franch_id') {
        optionText = `${option.first_name} ${option.last_name} | ${option.city.split('|||')[0]}`;
      } else if (input.name === 'contract') {
        optionText = `${option.label}`;
      }
      return optionText;
    }}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    value={input.value || null}
    onChange={(event, newValue) => {
      input.onChange(newValue);
    }}
    loadingText={i18n.t('text.loadingText')}
    noOptionsText={i18n.t('text.nothingFound')}
    loading={!custom.options.length}
    renderInput={(params) => (
      <TextField
        {...params}
        error={touched && !!error}
        helperText={touched && !!error && error}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <div>
              {!custom.options.length ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </div>
          )
        }}
        {...custom}
      />
    )}
  />
);

const renderDatePicker = ({
  input: { onBlur, value, ...inputProps },
  meta: { touched, error },
  ...custom
}) => {
  const _val = new Date(value);
  if (value) {
    // eslint-disable-next-line
    let date = _val == 'Invalid Date' ? new Date(JSON.parse(value)) : _val;
    date = new Date(date);
    const _date = `${date.getFullYear()}-${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}T00:00:00`;
    value = _date;
  }
  const onChange = (dates) => {
    inputProps.onChange((Date.parse(dates) && dates.toISOString()) || null);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={uaLocale}>
      <MobileDatePicker
        {...inputProps}
        {...custom}
        autoOk
        allowKeyboardControl={false}
        error={touched && !!error}
        animateYearScrolling
        disablePast
        variant="inline"
        inputVariant="outlined"
        format="dd.MM.yyyy"
        value={value ? new Date(value) : null}
        onChange={onChange}
        renderInput={(params) => <TextField {...custom} {...params} error={touched && !!error} />}
      />
    </LocalizationProvider>
  );
};
const handleSubmit = async (dataForm, dispatch) => {
  if (dataForm && !Object.keys(dataForm).includes('date_activation')) {
    throw new SubmissionError({ date_activation: 'error' });
  }
  dispatch(change('AddEabacus', '_error', null));
  dispatch(change('AddEabacus', 'loading', true));
  const toSend = JSON.parse(JSON.stringify(dataForm));
  const { macValues } = store.getState().usersList;
  toSend.franch_id = toSend.franch_id.id;
  toSend.contract = toSend.contract.id;
  /* eslint-disable-next-line */
  for (const item of (macValues.length && macValues) || [toSend.mac]) {
    toSend.mac = item.toUpperCase();
    delete toSend._error;
    delete toSend.loading;
    /* eslint-disable-next-line */
    const res = await addNewAbacus(toSend)(dispatch);
    if (res && res.error) {
      if (res.message === 'Invalid Mac') {
        dispatch(change('AddEabacus', '_error', { text: res.message, value: toSend.mac }));
      } else if (res.message === 'User not found') {
        dispatch(change('AddEabacus', 'loading', false));
        throw new SubmissionError({ franch_id: res.message });
      } else if (res.message === 'Such abacus already exist') {
        dispatch(change('AddEabacus', '_error', { text: res.message, value: toSend.mac }));
      }
      dispatch(change('AddEabacus', 'loading', false));
      break;
    }
  }
  dispatch(setMacValues([]));
  dispatch(reset('AddEabacus'));
  dispatch(closeDialog());
  const { esoroban } = store.getState();
  dispatch(getAllEabacuses(esoroban));
  toastSuccess(`${i18n.t(`modalEdit.toastSuccess`)}`);
};

const getValid = (values) =>
  new Promise((resolve, reject) => {
    if (values && values.mac && !values.mac.includes('_')) {
      return EabacusService.ValidateEabacusMac(values.mac.toUpperCase()).then((res) => {
        if (res && res.error && res.error === 'Conflict') {
          resolve(res && res.message);
        } else if (res && !res.isValid) {
          resolve(res && res.isValid);
        } else if (res && res.isValid) {
          resolve(null);
        }
      });
    }
    resolve(null);
  });

const asyncValidate = (values) =>
  getValid(values).then((res) => {
    if (res === false) {
      const error = { mac: 'MAC не правильный' };
      throw error;
    }
    if (typeof res === 'string') {
      const error = { mac: res };
      throw error;
    }
  });

export function AddDialog(props) {
  const { t, i18n } = useTranslation();
  const { franch, macValues } = useSelector((state) => state.usersList);
  const { AddEabacus } = useSelector((state) => state.form);
  const [value, setValue] = useState(null);
  const margin = 2;

  const handleAddMac = (item) => {
    if (!macValues.includes(item)) {
      store.dispatch(setMacValues([item, ...macValues]));
      store.dispatch(change('AddEabacus', 'mac', null));
      store.dispatch(untouch('AddEabacus', 'mac'));
    }
  };

  const handleRemoveMac = (item) => {
    store.dispatch(setMacValues([...macValues.filter((i) => i !== item)]));
  };

  const AsyncErrors =
    AddEabacus && AddEabacus.asyncErrors && Object.keys(AddEabacus.asyncErrors).includes('mac');
  const AsyncValidation = AddEabacus && AddEabacus.asyncValidating;
  const values =
    AddEabacus &&
    AddEabacus.values &&
    AddEabacus.values.mac &&
    !AddEabacus.values.mac.includes('_');
  const error = AddEabacus && AddEabacus.values && AddEabacus.values._error;
  const loading = AddEabacus && AddEabacus.values && AddEabacus.values.loading;

  return (
    <Form onSubmit={props.handleSubmit}>
      <DialogContentText component="div" id="alert-dialog-slide-description">
        {macValues.map((el, index) => (
          <Box mb={margin} mt={margin} key={index}>
            <Chip
              label={el}
              color={(error && error.value && error.value === el && 'error') || 'default'}
              variant={(error && error.value && error.value === el && 'outlined') || 'filled'}
              onDelete={() => handleRemoveMac(el)}
            />
          </Box>
        ))}

        <Box mb={margin} mt={margin}>
          <Field
            name="mac"
            className="macField"
            sx={{ width: 300 }}
            component={renderMacTextField}
            label={t('addAbacus.abacusMAC')}
            variant="outlined"
            required={!macValues.length}
          />
        </Box>

        <Button
          onClick={() => handleAddMac(AddEabacus.values.mac.toUpperCase())}
          disabled={!!AsyncErrors || !values || !!AsyncValidation}
        >
          {t('buttons.addMAC')}
        </Button>

        <Box mb={margin} mt={margin}>
          <Field
            name="franch_id"
            i18n={i18n}
            component={renderSelect}
            options={franch}
            sx={{ width: 300 }}
            required
            label={t('addAbacus.franch')}
            variant="outlined"
          />
        </Box>
        <Box mb={margin} mt={margin}>
          <Field
            name="contract"
            sx={{ width: 300 }}
            i18n={i18n}
            component={renderSelect}
            options={[
              { label: t('addAbacus.rent'), id: 'rent' },
              { label: t('addAbacus.buy'), id: 'buy' },
              { label: t('addAbacus.subscription'), id: 'subscribe' }
            ]}
            required
            label={t('addAbacus.contractType')}
            variant="outlined"
          />
        </Box>
        <Box mb={margin} mt={margin}>
          <Field
            name="date_activation"
            sx={{ width: 300 }}
            component={renderDatePicker}
            required
            label={t('addAbacus.activationDate')}
            variant="outlined"
          />
        </Box>
      </DialogContentText>
      <Collapse in={!!error}>
        <Box pt={2} pb={2}>
          <Typography align="center" variant="error" component="p" sx={{ width: 300 }}>
            {error && error.text}
          </Typography>
        </Box>
      </Collapse>
      <DialogActions>
        <Button onClick={props.closeDialog}>{t('addAbacus.buttonClose')}</Button>
        <LoadingButton
          type="submit"
          loading={loading}
          disabled={!!AsyncErrors || !values || !!AsyncValidation}
        >
          {t('addAbacus.buttonApply')}
        </LoadingButton>
      </DialogActions>
    </Form>
  );
}

AddDialog.propTypes = {
  closeDialog: PropTypes.func
};

export default reduxForm({
  onSubmit: handleSubmit,
  form: 'AddEabacus',
  destroyOnUnmount: false,
  asyncValidate,
  enableReinitialize: true,
  asyncChangeFields: ['mac']
})(AddDialog);
