import Types from '../constants/dialog';

const defaultState = {
  open: false,
  data: null,
  component: null,
  header: null,
  acceptHandler: null
};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.OPEN_DIALOG:
      return {
        ...state,
        ...action.payload
      };
    case Types.CLOSE_DIALOG:
      return { ...state, ...defaultState };
    default:
      return state;
  }
};

export default reducer;
