import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  DialogContentText
} from '@mui/material';
import store from '../../../store';
import { updateEsoroban, getEabacusFirmware } from '../../../actions/eabacus';
import { toastSuccess } from '../../../actions/toast';

export default function ManageMotoHoursDialog({ closeDialog, data }) {
  const [firmwareVersion, setFirmware] = useState('');

  const { firmware } = useSelector((state) => state.usersList);
  const { info } = useSelector((state) => state.user);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      dispatch(getEabacusFirmware());
    }

    fetchData();
  }, []);
  const handleUpdateEsoroban = async (version) => {
    const toSend = {
      macs: data.selected,
      firmware: version || firmware.find((el) => el.title === 'latest').code
    };
    await updateEsoroban(toSend)(store.dispatch, store.getState);
    toastSuccess(`${i18n.t(`update.toastSuccess`)}`);
    closeDialog();
  };
  const handleChange = (event) => {
    setFirmware(event.target.value);
  };
  const roleId = info.role_id;
  return (
    <>
      {roleId === 1 && (
        <Box sx={{ minWidth: 120, marginTop: '20px', marginBottom: '20px' }}>
          <FormControl fullWidth>
            <InputLabel id="firmware-select">{t('update.firmwareVersions')}</InputLabel>
            <Select
              labelId="firmware-select"
              value={firmwareVersion}
              label={t('updateAbacus.firmwareVersions')}
              onChange={handleChange}
            >
              {firmware.map((el, index) => (
                <MenuItem key={index} value={el}>
                  {(el.title === 'latest' && `${t('update.latest')} (${el.code})`) || el.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <DialogContentText
        component="div"
        sx={{ width: 300, marginBottom: '20px' }}
        id="alert-dialog-slide-description"
      >
        {`${
          (data.selected.length > 1 && `${data.selected.length} ${t('update.abacus_')}`) ||
          `${t('update.abacus')} ${data.selected[0]}`
        } ${t('update.willBeUpdate')} ${
          (!firmwareVersion && firmware.find((el) => el.title === 'latest').code) ||
          firmwareVersion.code
        }.`}
      </DialogContentText>
      <DialogActions>
        <Button onClick={closeDialog}>{t('update.buttonClose')}</Button>
        <Button onClick={() => handleUpdateEsoroban(firmwareVersion && firmwareVersion.code)}>
          {t('update.buttonUpdate')}
        </Button>
      </DialogActions>
    </>
  );
}
