/* eslint-disable */

import store from '../store';
import API_ROOT from '../constants/urlAPI';
import { OLD_API } from '../constants/oldApiUrl';
import Auth from './auth.service';
import { resume } from '../actions/user';
import { toastInfo } from '../actions/toast';
export const GET = (...args) => makeRequest('GET', ...args);
export const POST = (...args) => makeRequest('POST', ...args);
export const PUT = (...args) => makeRequest('PUT', ...args);
export const DELETE = (...args) => makeRequest('DELETE', ...args);

const formData = (object) =>
  Object.keys(object).reduce((form, key) => {
    form.append(key, object[key]);
    return form;
  }, new FormData());

async function makeRequest(
  method,
  endpoint,
  rawData = false,
  multipart = false,
  oldApi = false,
  token = false
) {
  const accessToken = store.getState().user && store.getState().user.access_token;
  const headers = oldApi
    ? new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      })
    : new Headers({
        'Content-Type': 'application/json'
      });
  if (accessToken) {
    headers.append('authorization', `Basic ${accessToken}`);
  }

  if (token) {
    headers.append('emulation', `Basic ${token}`);
  }

  const options = {
    method,
    headers,
    mode: 'cors',
    cache: 'default'
  };
  const body =
    !!rawData &&
    ((multipart && formData(rawData)) ||
      (oldApi && makeToQuery(rawData, true)) ||
      JSON.stringify(rawData));

  if (body) {
    Object.assign(options, { body });
  }
  const { user } = store.getState();

  const request = oldApi ? new Request(oldApi, options) : new Request(API_ROOT + endpoint, options);

  try {
    const data = await fetch(request);
    const json = await data.json();
    let reqestResult;
    if (oldApi) {
      let result = true;
      if (json.info === 'Redirect to new api') {
        result = true;
      } else if (!Object.keys(json).length) {
        result = true;
      } else if (!json.data.length) {
        result = true;
      } else if (json.data[0].role === 'boss' || json.data[0].role === 'superadmin') {
        result = true;
      } else if (json.data.length >= 1) {
        document.location.href = 'https://p.soroban.ua';
        result = false;
      }
      return result;
    }
    if (json.status) {
      return json.payload;
    } else if (json.error === 'Token is outdated') {
      const refreshToken = store.getState().user && store.getState().user.refresh_token;
      const resumeRequest = await Auth.Resume({ refresh_token: refreshToken });
      resume(resumeRequest)(store.dispatch, store.getState);
      const res = await makeRequest(method, endpoint, rawData, multipart, oldApi, token);
      return res;
    }
    if (json.error === 'Wrong email' || json.error === 'Wrong password') {
      toastInfo('Incorrect data');
    } else {
      const error = new Error(json.error);
      error.name = 'API';
      error.number = data.status;
      error.url = data.url;
      throw error;
    }
    return reqestResult;
  } catch ({ message, name, number, url }) {
    if (oldApi) {
      return true;
    }
    // console.log({ message, name, number, url });
    // throw 'ERROR => MAKE REQUEST!';
    if (number === 403) {
      if (message === 'Token has been changed') {
        localStorage.setItem('esorobanStore', JSON.stringify({}));
        document.location = `${document.location.origin}/login`;
      }
    } else if (number === 401) {
      if (message === 'Token recovery is not subject to') {
        localStorage.setItem('esorobanStore', JSON.stringify({}));
        document.location.reload(true);
      }
      // console.log("there is 401");
    } else {
      return Promise.reject(
        name === 'API' ? message : 'Ошибка: не удалось получить данные с сервера'
      );
    }
  }
}

const makeToQuery = (query, noGet = false) => {
  if (!query) return '';
  const str = [];
  for (const p of Object.keys(query))
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(query[p])}`);
  if (noGet) return str.join('&');
  return `?${str.join('&')}`;
};

export const ApiConnector = (prefix = '') => {
  return (callback, endpoint, multipart = false, query = false, oldApi = false, token = false) => ({
    async call(body = false, skipGlobalErrorStack = false) {
      if (oldApi) {
        oldApi = OLD_API + endpoint;
      }
      try {
        return await callback(
          ...[prefix + endpoint + makeToQuery(query), body, multipart, oldApi, token]
        );
      } catch (message) {
        // store.dispatch({
        //     type: Types.API_ERROR,
        //     payload: {
        //         id: uuid(),
        //         time: Date.now(),
        //         endpoint: prefix + endpoint,
        //         body,
        //         message
        //     }
        // });
        //
        // if (skipGlobalErrorStack) throw new Error(message);
        // else {
        //     enqueueSnackbar({
        //         message,
        //         options: {
        //             variant: "error"
        //         }
        //     })(store.dispatch);
        // }
      }
    }
  });
};
