import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useSelector, useDispatch, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Skeleton,
  Box,
  Chip,
  Collapse,
  Grow
} from '@mui/material';
import CircularProgress from '../components/CircularProgress';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { connectWs } from '../actions/websocket';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import { getAllEabacuses, changeFilterValues, getEabacusFirmware } from '../actions/eabacus';
import { processDate } from '../actions/date';
import { getFranchList, getCountriesList } from '../actions/usersList';
import { openDialog } from '../actions/dialog';
import store from '../store';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: '' },
  { id: 'mac', label: 'MAC', alignRight: false },
  { id: 'serialNumber', label: 'ID', alignRight: false },
  { id: 'status', label: 'table.status', alignRight: false },
  { id: 'battery', label: 'table.battery', alignRight: false },
  { id: 'fw', label: 'table.version', alignRight: false },
  { id: 'motoHours', label: 'table.motoHours', alignRight: false },
  { id: 'online', label: 'Online', alignRight: false },
  { id: 'ip', label: 'IP', alignRight: false },
  { id: 'ssid', label: 'Wi-Fi', alignRight: false },
  { id: 'franchName', label: 'table.owner', alignRight: false },
  { id: 'geo', label: 'GEO', alignRight: false },
  { id: 'contract', label: 'table.сontract', alignRight: false },
  { id: 'studentName', label: 'table.user', alignRight: false },
  { id: 'ammount', label: 'table.ammount', alignRight: false },
  { id: 'payedDate', label: 'table.payedDate', alignRight: false },
  { id: 'payDay', label: 'table.payDay', alignRight: false }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.mac.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const deleteFilterChips = (code) => {
  const { esoroban } = store.getState();
  store.dispatch(changeFilterValues(esoroban.filter.filter((el) => el.code !== code)));
  store.dispatch(getAllEabacuses(store.getState().esoroban));
};

export function User({ ...props }) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [webSoket, setWebSoket] = useState(null);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const esoroban = useSelector((state) => state.esoroban);
  const esorobanList = esoroban && esoroban.data;
  const { info } = useSelector((state) => state.user);
  const roleId = info.role_id;
  const dispatch = useDispatch();
  useEffect(() => {
    let ws;
    async function fetchData() {
      if (roleId === 1) dispatch(getCountriesList());
      if (roleId < 3) {
        dispatch(getFranchList());
      }
      dispatch(getEabacusFirmware());

      await dispatch(getAllEabacuses(esoroban));
      ws = new WebSocket('wss://e_dev1.soroban.ua:9003/');
      ws.onclose = (event) => {
        if (event.code !== 3010) {
          ws = new WebSocket('wss://e_dev1.soroban.ua:9003/');
          connectWs(ws);
          setWebSoket(ws);
        }
      };
      connectWs(ws);
      setWebSoket(ws);
    }

    fetchData();
    return () => {
      ws.close();
    };
  }, []);

  const closeWebSoket = () => {
    webSoket.close(3010, 'Reconnect');
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = esorobanList.map((n) => n.mac);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, mac) => {
    const selectedIndex = selected.indexOf(mac);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, mac);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(getAllEabacuses(esoroban, { page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(getAllEabacuses(esoroban, { rowsPerPage: event.target.value, page: 1 }));
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - esorobanList.length) : 0;

  const filteredUsers = applySortFilter(esorobanList, getComparator(order, orderBy), filterName);

  return (
    <Page title="Рахівниці | E-SOROBAN">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom onClick={closeWebSoket}>
            E-SOROBAN CONTROL
          </Typography>
          {roleId === 1 && (
            <Button
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={() =>
                dispatch(openDialog({ component: 'AddEabacus', header: t('addAbacus.addAbacus') }))
              }
            >
              {t('buttons.addAbacus')}
            </Button>
          )}
        </Stack>
        <Collapse in={esoroban && esoroban.filter && esoroban.filter.length > 0}>
          <Stack direction="row" alignItems="start" justifyContent="start" mb={1}>
            {esoroban.filter.map((el, index) => (
              <Grow in key={index}>
                <Box sx={{ margin: '5px' }}>
                  <Chip
                    label={el.item}
                    variant="outlined"
                    onDelete={() => deleteFilterChips(el.code)}
                  />
                </Box>
              </Grow>
            ))}
          </Stack>
        </Collapse>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            fetch={esorobanList.map((el) => el.wsUpdated)}
          />

          <Scrollbar>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={esorobanList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.map((row, index) => {
                    const {
                      status,
                      mac,
                      fw,
                      motoHours,
                      ssid,
                      price,
                      payPast,
                      payNext,
                      contract,
                      ip,
                      studentName,
                      franchName,
                      serialNumber,
                      battery,
                      st,
                      online,
                      upd,
                      franchCountry,
                      franchCity
                    } = row;
                    const isItemSelected = selected.indexOf(mac) !== -1;

                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, mac)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <UserMoreMenu {...row} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {mac}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{serialNumber}</TableCell>
                        <TableCell align="left">{status}</TableCell>
                        <TableCell align="left">
                          {(battery === -1 && <Skeleton width="53px" height="39px" />) || (
                            <Typography
                              className="content"
                              dangerouslySetInnerHTML={{ __html: battery }}
                            />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {(fw === -1 && <Skeleton width="56px" height="22px" />) ||
                            (upd && upd.includes('%') && +upd.replace(/[^.,\d]/g, '') && (
                              <CircularProgress value={upd} />
                            )) ||
                            fw}
                        </TableCell>
                        <TableCell align="left">
                          {(motoHours === -1 && <Skeleton width="56px" height="22px" />) || (
                            <Label
                              variant="ghost"
                              color={(motoHours === 0 && 'error') || 'success'}
                            >
                              {sentenceCase((motoHours === 0 && 'No') || 'Yes')}
                            </Label>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {(online === 'pending' && <Skeleton width="56px" height="22px" />) || (
                            <Label
                              variant="ghost"
                              color={
                                (online === 'offline' && 'error') ||
                                (online === 'pending' && 'info') ||
                                'success'
                              }
                            >
                              {sentenceCase(online)}
                            </Label>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {(ip === -1 && <Skeleton width="53px" height="22px" />) || ip}
                        </TableCell>
                        <TableCell align="left">
                          {(ssid === -1 && <Skeleton width="53px" height="39px" />) || ssid}
                        </TableCell>
                        <TableCell align="left">{`${franchName || '~' || ''}`}</TableCell>
                        <TableCell align="left">
                          {franchCountry && `${franchCountry} (${franchCity}) `}
                        </TableCell>
                        <TableCell align="left">
                          {(contract === 'rent' && t('addAbacus.rent')) ||
                            (contract === 'buy' && t('addAbacus.buy')) ||
                            (contract === 'subscribe' && t('addAbacus.subscription'))}
                          {!contract ? '~' : ''}
                        </TableCell>
                        <TableCell align="left">{`${studentName || '~' || ''}`}</TableCell>
                        <TableCell align="left">{`${price ? `${price}$` : '~'}`}</TableCell>
                        <TableCell align="left">{processDate(payPast)}</TableCell>
                        <TableCell align="left">{processDate(payNext)}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {esoroban && esoroban.noData && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound
                          searchQuery={
                            (esoroban.filter &&
                              esoroban.filter.length > 0 &&
                              esoroban.filter.map((el) => el.item).join(', ')) ||
                            ''
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {esoroban && !esoroban.count && !esoroban.noData && (
                  <TableBody>
                    {new Array(5).fill(0).map((el, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ padding: '27px' }} colSpan={18} height={50}>
                          <Box>
                            <Skeleton />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={esoroban && esoroban.count}
            rowsPerPage={esoroban && esoroban.rowsPerPage}
            page={esoroban && esoroban.page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  esoroban: state.esoroban
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ getAllEabacuses }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(User);
