import { useState, useEffect } from 'react';
import {
  Field,
  reduxForm,
  Form,
  reset,
  SubmissionError,
  untouch,
  change,
  clearFields
} from 'redux-form';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Box,
  DatePicker,
  Chip,
  Collapse,
  Typography
} from '@mui/material';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import uaLocale from 'date-fns/locale/uk';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LoadingButton } from '@mui/lab';

import store from '../../../store';
import { setMacValues } from '../../../actions/usersList';
import { editEabacus, getAllEabacuses } from '../../../actions/eabacus';
import { closeDialog } from '../../../actions/dialog';
import { toastSuccess } from '../../../actions/toast';

import EabacusService from '../../../services/eabacus.service';

const renderSelect = ({ input, meta: { touched, error }, i18n, ...custom }) => (
  <Autocomplete
    options={custom.options || []}
    sx={{ width: '100%' }}
    getOptionLabel={(option) => {
      let optionText;
      if (input.name === 'owner_id') {
        optionText = `${option.first_name} ${option.last_name} | ${option.city.split('|||')[0]}`;
      } else if (input.name === 'contract' || input.name === 'status') {
        optionText = `${option.label}`;
      }
      return optionText;
    }}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    value={input.value || null}
    onChange={(event, newValue) => {
      input.onChange(newValue);
      if (newValue === null) store.dispatch(clearFields('EditEabacus', false, false, input.name));
    }}
    loadingText={i18n.t('text.loadingText')}
    noOptionsText={i18n.t('text.nothingFound')}
    loading={!custom.options.length}
    renderInput={(params) => (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <div>
              {!custom.options.length ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </div>
          )
        }}
        {...custom}
      />
    )}
  />
);

const renderDatePicker = ({
  input: { onBlur, value, ...inputProps },
  meta: { touched, error },
  ...custom
}) => {
  const _val = new Date(value);
  if (value) {
    // eslint-disable-next-line
    let date = _val == 'Invalid Date' ? new Date(JSON.parse(value)) : _val;
    date = new Date(date);
    const _date = `${date.getFullYear()}-${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}T00:00:00`;
    value = _date;
  }
  const onChange = (dates) => {
    inputProps.onChange((Date.parse(dates) && dates.toISOString()) || null);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={uaLocale}>
      <MobileDatePicker
        {...inputProps}
        {...custom}
        autoOk
        allowKeyboardControl={false}
        error={touched && !!error}
        animateYearScrolling
        disablePast
        variant="inline"
        inputVariant="outlined"
        format="dd.MM.yyyy"
        value={value ? new Date(value) : null}
        onChange={onChange}
        renderInput={(params) => <TextField {...custom} {...params} error={touched && !!error} />}
      />
    </LocalizationProvider>
  );
};
const handleSubmit = async (dataForm, dispatch) => {
  dispatch(change('EditEabacus', 'loading', true));
  const toSend = JSON.parse(JSON.stringify(dataForm));
  const sendObject = {};
  delete toSend._error;
  delete toSend.loading;
  // eslint-disable-next-line
  for (const item in toSend) {
    if (!Array.isArray(toSend[item])) {
      sendObject[item] = (typeof toSend[item] === 'object' && toSend[item].id) || toSend[item];
    } else {
      sendObject[item] = toSend[item];
    }
  }
  const res = await editEabacus(sendObject)(dispatch);
  if (res && res.error) {
    if (res.message === 'User not found') {
      throw new SubmissionError({ owner_id: res.message });
    }
    return;
  }
  dispatch(reset('EditEabacus'));
  dispatch(closeDialog());
  const { esoroban } = store.getState();
  dispatch(getAllEabacuses(esoroban));
  toastSuccess(`${i18n.t(`addAbacus.toastSuccess`)}`);
};

export function EditDialog({ data, ...props }) {
  const { franch, macValues } = useSelector((state) => state.usersList);
  const { EditEabacus } = useSelector((state) => state.form);
  const margin = 2;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(change('EditEabacus', 'macs', data.selected));
  }, []);
  const error = EditEabacus && EditEabacus.values && EditEabacus.values._error;
  const loading = EditEabacus && EditEabacus.values && EditEabacus.values.loading;
  const values = EditEabacus && EditEabacus.values;
  return (
    <Form onSubmit={props.handleSubmit}>
      <DialogContentText component="div" sx={{ width: 300 }} id="alert-dialog-slide-description">
        {`${t('modalEdit.edit')} ${`${data.selected.length} ${
          (data.selected.length > 1 && t('modalEdit.abacus')) || t('modalEdit.abacus_')
        }`}`}
      </DialogContentText>
      <Box mb={margin} mt={margin}>
        <Field
          name="owner_id"
          i18n={i18n}
          component={renderSelect}
          options={franch}
          sx={{ width: 300 }}
          label={t('modalEdit.franch')}
          variant="outlined"
        />
      </Box>
      <Box mb={margin} mt={margin}>
        <Field
          name="contract"
          sx={{ width: 300 }}
          i18n={i18n}
          component={renderSelect}
          options={[
            { label: t('modalEdit.rent'), id: 'rent' },
            { label: t('modalEdit.buy'), id: 'buy' },
            { label: t('modalEdit.subscription'), id: 'subscribe' }
          ]}
          label={t('modalEdit.contractType')}
          variant="outlined"
        />
      </Box>
      <Box mb={margin} mt={margin}>
        <Field
          name="status"
          sx={{ width: 300 }}
          i18n={i18n}
          component={renderSelect}
          options={[
            { label: t('modalEdit.new'), id: 'new' },
            { label: t('modalEdit.factory'), id: 'factory' },
            { label: t('modalEdit.active'), id: 'active' }
          ]}
          label={t('modalEdit.abacusStatus')}
          variant="outlined"
        />
      </Box>
      <Box mb={margin} mt={margin}>
        <Field
          name="pay_next"
          sx={{ width: 300 }}
          component={renderDatePicker}
          label={t('modalEdit.activationDate')}
          variant="outlined"
        />
      </Box>
      <Collapse in={!!error}>
        <Box pt={2} pb={2}>
          <Typography align="center" variant="error" component="p" sx={{ width: 300 }}>
            {error && error.text}
          </Typography>
        </Box>
      </Collapse>
      <DialogActions>
        <Button onClick={props.closeDialog}>{t('modalEdit.buttonClose')}</Button>
        <LoadingButton
          type="submit"
          loading={loading}
          disabled={!(values && Object.keys(values).length > 1)}
        >
          {t('modalEdit.buttonApply')}
        </LoadingButton>
      </DialogActions>
    </Form>
  );
}

EditDialog.propTypes = {
  closeDialog: PropTypes.func
};

export default reduxForm({
  onSubmit: handleSubmit,
  form: 'EditEabacus'
})(EditDialog);
