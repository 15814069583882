import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { connect, useSelector } from 'react-redux';
import { closeDialog } from '../../actions/dialog';
import store from '../../store';
import Components from './components';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AlertDialogSlide = () => {
  const dialog = useSelector((state) => state.dialog);

  const handleClose = () => {
    closeDialog()(store.dispatch, store.getState);
  };

  return (
    <Dialog
      open={dialog.open}
      maxWidth="lg"
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{dialog.header}</DialogTitle>
      <DialogContent children={<Components data={dialog} />} />
    </Dialog>
  );
};

export default connect(null, { closeDialog })(AlertDialogSlide);
