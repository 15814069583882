import base64url from 'base64url';
import Types from '../constants/user';
import AuthService from '../services/auth.service';
import UserService from '../services/users.service';

const tokenTransformToInfo = (token) => {
  const middleSplitToken = token.split('.')[1];
  return JSON.parse(base64url.decode(middleSplitToken));
};

async function checkOldApi(data) {
  const res = await AuthService.OldApiCheck(data);
  return res;
}

function logIn(data) {
  return async (dispatch, getState) => {
    const { user } = getState();
    const response = await AuthService.Login(data);
    if (response) {
      dispatch({
        type: Types.LOGIN,
        user: {
          ...user,
          multiprofile: response.multiprofile,
          transactionKey: response.transactionKey,
          loginView: response.status
        }
      });
      return response;
    }
    return false;
  };
}

function setProfile({ id }) {
  return async (dispatch, getState) => {
    const { user } = getState();
    const response = await AuthService.SelectProfile({
      id,
      transactionKey: user.transactionKey
    });
    const dataInfoToken = tokenTransformToInfo(response.token) || {};
    const storageNotVerified = localStorage.getItem('esorobanStore');
    const storage = storageNotVerified ? JSON.parse(storageNotVerified) : { user: {} };
    const { info } = dataInfoToken;
    const data = {
      user: {
        ...storage.user,
        ...user,
        access_token: response.token,
        refresh_token: response.refresh_token,
        loggedin: response.status,
        transactionKey: user.transactionKey,
        loginView: null,
        multiprofile: [],
        info,
        permission: dataInfoToken.permission,
        animation: 0
      }
    };
    localStorage.setItem('esorobanStore', JSON.stringify({ ...storage, ...data }));
    await dispatch({
      type: Types.SETPROFILE,
      ...data
    });
    return response;
  };
}

function resume(response) {
  return (dispatch, getState) => {
    const { user } = getState();
    const dataInfoToken = tokenTransformToInfo(response.token) || {};
    const storageNotVerified = localStorage.getItem('esorobanStore');
    const storage = storageNotVerified ? JSON.parse(storageNotVerified) : { user: {} };
    dataInfoToken.info.user_id = dataInfoToken.user_id;
    const data = {
      user: {
        ...storage.user,
        ...user,
        access_token: response.token,
        loggedin: response.status,
        transactionKey: null,
        loginView: null,
        multiprofile: [],
        info: dataInfoToken.info,
        permission: dataInfoToken.permission
      }
    };
    localStorage.setItem('esorobanStore', JSON.stringify({ ...storage, ...data }));
    dispatch({
      type: Types.RESUME,
      ...data
    });
  };
}

// const browserHistory = createBrowserHistory();

async function getUsers(data, query, token = false) {
  const res = await UserService.UsersList(data, query, token);
  return res;
}

function logOut() {
  return (dispatch) => {
    localStorage.setItem('esorobanStore', JSON.stringify({}));
    setTimeout(() => {
      dispatch({
        type: Types.LOGOUT
      });
      return true;
    }, 300);
  };
}

export { logIn, logOut, setProfile, resume, checkOldApi, getUsers };
