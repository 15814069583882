/* eslint-disable */

import { ApiConnector, GET, POST } from './request.service';

export default (() => {
  const API = ApiConnector('/auth');
  return {
    Login: async (data) => {
      const res = await API(POST, '/login').call(data);
      return res;
    },
    SelectProfile: async (data) => {
      const res = await API(POST, '/profile-selection').call(data);
      return res;
    },
    Resume: async (data) => {
      const res = await API(POST, '/resume').call(data);
      return res;
    },
    Emulation: async (data) => {
      const res = await API(POST, '/emulation', false, false, false).call(data);
      return res;
    },
    OldApiCheck: async (data) => {
      const res = await API(POST, '/authorization', false, false, true).call(data);
      return res;
    }
  };
})();
