import { toast } from 'react-toastify';

const toastSettings = {
  position: 'top-left',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

export function toastInfo(text) {
  toast.info(text, toastSettings);
}

export function toastSuccess(text) {
  toast.success(text, toastSettings);
}

export function toastWarning(text) {
  toast.warn(text, toastSettings);
}

export function toastError(text) {
  toast.error(text, toastSettings);
}

export function toastDefault(text) {
  toast(text, toastSettings);
}
