//* ↓↓ LIST OF APIs ↓↓
const PROD_API = 'https://api-test-admin.e-soroban.com';
const TEST_API = 'https://api-test-admin.e-soroban.com';
const TEST_LOCAL_API = 'http://192.168.0.215:8000';

const HOSTNAME = window.location.hostname;

let URL = PROD_API;

const LOCAL_BACK = 0;
const PROD = 0;

//* ↓↓ API links process ↓↓
if (HOSTNAME.includes('local') || HOSTNAME.includes('192.168')) {
  URL = (PROD && PROD_API) || (LOCAL_BACK && TEST_LOCAL_API) || TEST_API;
} else if (HOSTNAME.includes('test')) {
  URL = (PROD && PROD_API) || TEST_API;
}
const data = URL;
export default data;
