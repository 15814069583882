import store from '../store';

// ----------------------------------------------------------------------
const user = store.getState() && store.getState().user && store.getState().user.info;
const account = {
  displayName: `${user && user.first_name} ${user && user.last_name}`,
  role: `${user && user.role.split('|||')[0]}`,
  photoURL: '/static/mock-images/avatars/avatar_default.jpg'
};

export default account;
