import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import ua from './locales/ua.json';

const domain =
  document.location.hostname.split('.')[document.location.hostname.split('.').length - 1];
// const schema = {
//   ua: { en, ua },
//   com: { en, ua },
// };
// if (!isNaN(+domain)) domain = "ua";
// if (!schema[domain]) domain = "ua";
i18n.use(initReactI18next).init({
  resources: { ua, en },
  lng: 'en',
  interpolation: {
    escapeValue: false
  }
});
const localStorageLang = localStorage.getItem('lang');
const langToUse = !localStorageLang ? 'en' : localStorageLang;
i18n.changeLanguage(langToUse);
if (!localStorageLang) localStorage.setItem('lang', 'en');
export default i18n;
