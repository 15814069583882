import Types from '../constants/dialog';

export function openDialog(data) {
  return (dispatch) => {
    dispatch({
      type: Types.OPEN_DIALOG,
      payload: {
        open: true,
        ...data
      }
    });
  };
}

export function closeDialog() {
  return (dispatch) => {
    dispatch({
      type: Types.CLOSE_DIALOG
    });
  };
}
