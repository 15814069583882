import DialogActions from '@mui/material/DialogActions';
import { useEffect } from 'react';

import { Field, reduxForm, Form, reset, change, clearFields } from 'redux-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { Autocomplete, TextField, CircularProgress, Box, Tooltip, IconButton } from '@mui/material';
import DateRangePicker from '@mui/lab/DateRangePicker';
import { Icon } from '@iconify/react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import uaLocale from 'date-fns/locale/uk';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import store from '../../../store';
import { changeFilterValues, getAllEabacuses } from '../../../actions/eabacus';
import { closeDialog } from '../../../actions/dialog';
import { processDate } from '../../../actions/date';
import { getFranchList } from '../../../actions/usersList';

const renderSelect = ({ input, i18n, esoroban, ...custom }) => (
  <Autocomplete
    options={custom.options || []}
    sx={{ width: '100%' }}
    getOptionLabel={(option) => {
      let optionText;
      if (input.name === 'city') {
        optionText = `${option.first_name} ${option.last_name} | ${option.city.split('|||')[0]}`;
      } else if (input.name === 'country') {
        optionText = `${option.first_name} ${option.last_name} | ${option.title.split('|||')[0]}`;
      } else if (input.name === 'contract') {
        optionText = `${option.label}`;
      }
      return optionText;
    }}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    value={input.value || null}
    onChange={(event, newValue) => {
      input.onChange(newValue);
      if (input.name === 'country') {
        store.dispatch(getFranchList(newValue !== null && newValue.boss_id, newValue !== null));
        store.dispatch(change('FilterForm', 'city', null));
      }
      if (newValue === null) store.dispatch(clearFields('FilterForm', false, false, input.name));
    }}
    loadingText={i18n.t('text.loadingText')}
    noOptionsText={i18n.t('text.nothingFound')}
    loading={!custom.options.length}
    renderInput={(params) => (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <div>
              {!custom.options.length ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </div>
          )
        }}
        {...custom}
      />
    )}
  />
);

const renderDatePicker = ({
  input: { onBlur, value, ...inputProps },
  meta: { touched, error },
  ...custom
}) => {
  const onChange = (dates) => {
    inputProps.onChange(dates);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={uaLocale}>
      <DateRangePicker
        calendars={3}
        {...inputProps}
        {...custom}
        onChange={onChange}
        cancelText="Отмена"
        clearable
        mask="__.__.____"
        value={value || [null, null]}
        renderInput={(startProps, endProps) => (
          <>
            <TextField {...startProps} label={custom.labelFrom} error={touched && !!error} />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} label={custom.labelTo} error={touched && !!error} />
          </>
        )}
      />
    </LocalizationProvider>
  );
};

const handleClearFilter = (esoroban) => {
  store.dispatch(reset('FilterForm'));
  store.dispatch(getAllEabacuses(esoroban, { page: 1 }));
  closeDialog();
};

const handleSubmit = async (dataForm, dispatch) => {
  const sendObject = [];
  // eslint-disable-next-line
  for (const item in dataForm) {
    if (
      dataForm[item] !== null &&
      typeof dataForm[item] === 'object' &&
      !Array.isArray(dataForm[item])
    ) {
      sendObject.push({
        code: item,
        values: dataForm[item].id,
        item: `${dataForm[item].first_name} ${dataForm[item].last_name} | ${
          dataForm[item][(item === 'country' && 'title') || 'city'].split('|||')[0]
        }`
      });
    } else if (Array.isArray(dataForm[item])) {
      if (dataForm[item][0] !== null)
        sendObject.push({
          code: `${item}Start`,
          values: dataForm[item][0],
          item: `${i18n.t(`filters_eabacus.${item}Start`)} ${processDate(dataForm[item][0])}`
        });
      if (dataForm[item][1] !== null)
        sendObject.push({
          code: `${item}End`,
          values: dataForm[item][1],
          item: `${i18n.t(`filters_eabacus.${item}End`)}  ${processDate(dataForm[item][1])}`
        });
    }
  }
  dispatch(changeFilterValues(sendObject));
  const { esoroban } = store.getState();
  dispatch(getAllEabacuses(esoroban, { page: 1 }));
  dispatch(closeDialog(closeDialog));
};

function FilterDialog({ closeDialog, ...props }) {
  const esoroban = useSelector((state) => state.esoroban);
  const { franch, countries } = useSelector((state) => state.usersList);
  const { filter } = useSelector((state) => state.esoroban);
  const { info } = useSelector((state) => state.user);
  const roleId = info.role_id;
  const { t } = useTranslation();
  useEffect(() => {
    function findFunction(data, code) {
      return data.find((el) => el.code === code) && data.find((el) => el.code === code).values;
    }
    function initForm(data) {
      if (data && data.length) {
        store.dispatch(
          change(
            'FilterForm',
            'country',
            findFunction(data, 'country') &&
              countries.find((el) => +el.id === +findFunction(data, 'country'))
          )
        );
        store.dispatch(
          change(
            'FilterForm',
            'city',
            findFunction(data, 'city') &&
              franch.find((el) => +el.id === +findFunction(data, 'city'))
          )
        );
        store.dispatch(
          change(
            'FilterForm',
            'payDate',
            [
              findFunction(data, 'payDateStart') || null,
              findFunction(data, 'payDateEnd') || null
            ] || [null, null]
          )
        );
        store.dispatch(
          change(
            'FilterForm',
            'payNext',
            [
              findFunction(data, 'payNextStart') || null,
              findFunction(data, 'payNextEnd') || null
            ] || [null, null]
          )
        );
      }
    }
    initForm(filter);
  }, [filter]);
  const margin = 2;
  return (
    <Form onSubmit={props.handleSubmit}>
      {roleId === 1 && (
        <Box mb={margin} mt={margin}>
          <Field
            name="country"
            i18n={i18n}
            component={renderSelect}
            options={countries}
            sx={{ width: '100%' }}
            label={t('filter.country')}
            variant="outlined"
            esoroban={esoroban}
          />
        </Box>
      )}
      {roleId <= 2 && (
        <Box mb={margin} mt={margin}>
          <Field
            name="city"
            i18n={i18n}
            component={renderSelect}
            options={franch}
            sx={{ width: '100%' }}
            label={t('filter.franch')}
            variant="outlined"
          />
        </Box>
      )}

      <Box mb={margin} mt={margin}>
        <Field
          name="payDate"
          sx={{ width: 300 }}
          component={renderDatePicker}
          labelFrom={t('filter.dateFrom')}
          labelTo={t('filter.dateBefore')}
          required
          variant="outlined"
        />
      </Box>
      <Box mb={margin} mt={margin}>
        <Field
          name="payNext"
          sx={{ width: 300 }}
          component={renderDatePicker}
          labelFrom={t('filter.nextDateFrom')}
          labelTo={t('filter.nextDateBefore')}
          required
          variant="outlined"
        />
      </Box>

      <DialogActions>
        <Tooltip title={t('filter.clearFilter')}>
          <IconButton onClick={() => handleClearFilter(esoroban)}>
            <Icon icon="ic:baseline-delete-sweep" />
          </IconButton>
        </Tooltip>
        <Button onClick={closeDialog}>{t('filter.buttonClose')}</Button>
        <Button type="submit">{t('filter.buttonApply')}</Button>
      </DialogActions>
    </Form>
  );
}

FilterDialog.propTypes = {
  closeDialog: PropTypes.func
};

export default reduxForm({
  onSubmit: handleSubmit,
  form: 'FilterForm',
  enableReinitialize: true
})(FilterDialog);
