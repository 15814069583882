export function processDate(value) {
  if (+value) value = +value;
  const d = new Date(value);
  if (+d === 0) {
    return '~';
  }
  const _month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  const _date = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  const date = `${_date}.${_month}.${d.getFullYear()} `;
  return date;
}
